<template>
    <div class="release-details-dialog">
        <el-dialog
            v-loading="loading"
            title="发放明细"
            :visible.sync="dialogVisible"
            width="748px"
            :before-close="handleClose">
            <div class="content">
                <div class="header">
                    <span class="item">总金额<i>{{ numberFormatter(aggregateAmount) }}</i></span>
                    <span class="item">已领取金额<i>{{ numberFormatter(receivedAmount) }}</i></span>
                    <span class="item">发放个数<i>{{ sendCount }}</i></span>
                    <span class="item">领取个数<i>{{ receivedCount }}</i></span>
                </div>
                <div class="details-table">
                    <el-table
                        :data="detailsData"
                        style="width: 100%">
                        <el-table-column
                            prop="receivedTime"
                            label="领取时间"
                            :formatter="dateFormatter"
                            width="220">
                        </el-table-column>
                        <el-table-column
                            prop="receiverName"
                            label="用户">
                            <template slot-scope="scope">
                                <div class="user-info">
                                    <avatar
                                        :userId="scope.row.receiverId"
                                        :enableLink="false"
                                        :src="scope.row.avatarUrl"
                                        size="sm"
                                    ></avatar>
                                    <span class="name">{{ scope.row.receiverName }}</span>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="amount"
                            label="金额"
                            width="200">
                             <template slot-scope="scope">
                                {{ numberFormatter(scope.row.amount) }}
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import moment from 'moment';
import { currency as currencyFilter } from '#/js/filters/number.js';
import Avatar from '#/component/common/avatar.vue';
import broadcastService from '#/js/service/broadcastService.js';

export default {
    components: {
        Avatar,
    },
    data() {
        return {
            dialogVisible: false,
            loading: false,
            detailsData: [],

            aggregateAmount: 0, // 总金额
            receivedAmount: 0, // 已领取金额
            sendCount: 0, // 发放个数
            receivedCount: 0, // 领取个数
        };
    },

    mounted() {
        
    },

    methods: {
        dateFormatter(row, column, cellValue, index) {
            if(!cellValue || cellValue.includes('0001-01-01T00:00:00')) {
                return '';
            } else {
                return moment(cellValue).format('YYYY-MM-DD HH:mm');
            }
        },
        numberFormatter(cellValue) {
            return currencyFilter(cellValue, '', 2);
        },
        
        show(item) {
            // console.log(item);
            this.dialogVisible = true;

            this.loading = true;
            let params = {
                id: item.broadcastSendingLogId,
            };
            broadcastService
                .getBroadcastListDetail(params)
                .then(res => {
                    // console.log(res);
                    this.detailsData = res.receivedVOS;

                    this.aggregateAmount = res.aggregateAmount;
                    this.receivedAmount = res.receivedAmount;
                    this.sendCount = res.sendCount;
                    this.receivedCount = res.receivedCount;
                }).catch((err) => {
                    console.log(err);
                }).finally(() => {
                    this.loading = false;
                });
        },

        handleClose() {
            this.dialogVisible = false;
        },
    },
};
</script>

<style lang="scss" scoped>
.release-details-dialog {
    .el-dialog__body {
        padding: 20px;
        max-height: 500px;
        overflow-y: auto;
        .content {
            .header {
                margin-top: 10px;
                margin-bottom: 30px;
                .item {
                    margin-right: 40px;
                    color: #38BC9C;
                    font-size: 14px;
                    i {
                        margin-left: 10px;
                        color: #FF7200;
                        font-size: 16px;
                    }
                }
            }
            .details-table {
                /deep/ .el-table {
                    border: 1px solid #D3D3D3;
                    color: #666;
                    thead.has-gutter tr th {
                        background-color: #F8F8F8;
                        border: none;
                        .cell {
                            font-weight: normal;
                        }
                    }
                    tbody tr td {
                        border: none;
                    }

                    // .el-table__row:nth-child(2n) {
                    //     background-color: #F8F8F8;
                    // }
                    // .el-table__row:nth-child(2n+1) {
                    //     background-color: #fff;
                    // }
                    .el-table__row:nth-child(even) { // 偶数
                        background-color: #F8F8F8;
                    }
                    .el-table__row:nth-child(odd) { // 奇数
                        background-color: #fff;
                    }

                    .user-info {
                        display: flex;
                        .name {
                            margin-left: 10px;
                        }
                    }
                }
            }
        }
    }
}
</style>