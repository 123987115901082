<template>
    <div class="offer-broadcast-statistics-table">
        <div class="header-option-btn">
            <el-button
                type="primary"
                @click="goToWechatPlatform"
                >微信商户平台</el-button
            >
            <el-button
                type="primary"
                :loading="exportLoading"
                @click="handleExport"
                >导出明细</el-button
            >
        </div>

        <el-table
            v-loading="loading"
            :data="tableData"
            border
            style="width: 100%"
            tooltip-effect="light"
        >
            <el-table-column
                prop="offerTime"
                align="left"
                width="180"
                :formatter="dateFormatter"
                :show-overflow-tooltip="true"
                label="Offer时间"
            >
            </el-table-column>
            <el-table-column
                prop="sendingTime"
                align="left"
                width="180"
                :formatter="dateFormatter"
                :show-overflow-tooltip="true"
                label="播报推送时间"
            >
            </el-table-column>
            <el-table-column
                prop="customerName"
                align="left"
                width="200"
                :show-overflow-tooltip="true"
                label="客户"
            >
            </el-table-column>
            <el-table-column
                prop="recommendName"
                align="left"
                width="200"
                :show-overflow-tooltip="true"
                label="推荐人"
            >
            </el-table-column>
            <el-table-column
                prop="jobName"
                align="left"
                width="200"
                :show-overflow-tooltip="true"
                label="推荐职位"
            >
            </el-table-column>
            <el-table-column
                prop="aggregateAmount"
                align="left"
                width="160"
                :show-overflow-tooltip="true"
                label="红包金额"
            >
                <!-- toFixed -->
                <template slot-scope="scope">
                    <!-- {{ scope.row.aggregateAmount.toFixed(2) }} -->
                    {{ numberFormatter(scope.row.aggregateAmount) }}
                </template>
            </el-table-column>
            <el-table-column
                prop="receivedAmount"
                align="left"
                width="160"
                :show-overflow-tooltip="true"
                label="已领取金额"
            >
                <template slot-scope="scope">
                    <!-- {{ scope.row.receivedAmount.toFixed(2) }} -->
                    {{ numberFormatter(scope.row.receivedAmount) }}
                </template>
            </el-table-column>
            <el-table-column
                label="操作"
                width="140"
                align="center"
                :resizable="false"
            >
                <template slot-scope="scope">
                    <span class="details" @click="viewDetail(scope.row)">明细</span>
                </template>
            </el-table-column>
        </el-table>

        <!-- 分页 -->
        <div class="work-table-footer">
            <el-pagination
                class="el-pagination-workTable"
                layout="total, sizes, prev, pager, next, slot"
                :current-page="page.current"
                :page-sizes="[15, 20, 50]"
                :page-size="page.size"
                :total="page.total"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
            >
                <span class="pagination-text">
                    <span
                        >前往<el-input
                            v-model="pagerJump"
                            @keyup.enter.native="handlePagerJump"
                        ></el-input
                        >页</span
                    >
                    <span @click="handlePagerJump">跳转</span>
                </span>
            </el-pagination>
        </div>

        <release-details-dialog ref="releaseDetailsDialog"></release-details-dialog>
    </div>
</template>

<script>
import moment from 'moment';
import { currency as currencyFilter } from '#/js/filters/number.js';
import ReleaseDetailsDialog from '../component/release-details-dialog.vue';
import broadcastService from '#/js/service/broadcastService.js';
import { downloadFile } from "#/js/util/fileUtil.js";

export default {
    components: {
        ReleaseDetailsDialog,
    },
    data() {
        return {
            loading: false,
            exportLoading: false,
            pagerJump: 0,
            page: {
                current: 1,
                size: 15,
                total: 0
            },
            tableData: [],
        };
    },

    mounted() {
        this.getData();
    },

    methods: {
        dateFormatter(row, column, cellValue, index) {
            if(!cellValue || cellValue.includes('0001-01-01T00:00:00')) {
                return '';
            } else {
                return moment(cellValue).format('YYYY-MM-DD HH:mm');
            }
        },
        numberFormatter(cellValue) {
            return currencyFilter(cellValue, '', 2);
        },
        
        // 跳转到微信商户平台
        goToWechatPlatform() {
            window.open('https://pay.weixin.qq.com/');
        },
        // 导出
        handleExport() {
            this.exportLoading = true;
            let params = {
                offset: (this.page.current - 1) * this.page.size,
                pageSize: this.page.size,
            };
            broadcastService
                .exportBroadcast(params)
                .then(res => {
                    console.log(res);
                    downloadFile(res);
                }).catch((err) => {
                    console.log(err);
                }).finally(() => {
                    this.exportLoading = false;
                });
        },

        // 分页
        handleSizeChange(val) {
            this.page.current = 1;
            this.page.size = val;
            this.getData();
        },
        handleCurrentChange(val) {
            this.page.current = val;
            this.getData();
        },
        handlePagerJump() {
            let currentPager = Number(this.pagerJump),
                currentPageTotal = Math.ceil(this.page.total / this.page.size);
            if (currentPager > 0 && currentPager <= currentPageTotal) {
                this.handleCurrentChange(currentPager)
            }
        },

        // 获取数据统计列表
        getData() {
            this.loading = true;
            let params = {
                offset: (this.page.current - 1) * this.page.size,
                pageSize: this.page.size,
            };
            broadcastService
                .getBroadcastList(params)
                .then(res => {
                    // console.log(res);
                    this.tableData = res.data;
                    this.page.total = res.count;

                    document.querySelector('.offer-broadcast').scrollTop = 0;
                }).catch((err) => {
                    console.log(err);
                }).finally(() => {
                    this.loading = false;
                });
        },

        // 查看明细
        viewDetail(item) {
            console.log(item);
            this.$refs.releaseDetailsDialog.show(item);
        },
    },
};
</script>

<style lang="scss" scoped>
.offer-broadcast-statistics-table {
    padding-top: 10px;
    position: relative;

    .header-option-btn {
        position: absolute;
        right: 0;
        top: -46px;
        .el-button {
            height: 32px;
            line-height: 32px;
            border-radius: 4px;
            margin-right: 20px;
        }
    }

    /deep/ .el-table {
        th, td {
            padding: 8px 0;
        }
        thead.has-gutter tr th {
            background-color: #F0F4F7;
            .cell {
                color: #666;
                font-weight: bold;
                padding: 0 20px;
            }
        }
        td .cell {
            padding: 0 20px;
        }

        .details {
            cursor: pointer;
            &:hover {
                color: #38bc9d;
            }
        }
    }

    .work-table-footer {
        margin: 35px 35px 0 0;
        overflow: hidden;
    }
}
</style>
<style lang="scss">
.offer-broadcast-statistics-table {
    .el-table--border .el-table__cell {
        border: none;
    }
    .el-table th.el-table__cell.is-leaf,
    .el-table td.el-table__cell {
        border: none;
    }
    .el-table thead th.el-table__cell {
        background: #f8f8f8;
    }
    .el-table__empty-block {
        width: 100% !important;
    }

    .ell {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .num {
        color: #38bc9d;
        cursor: pointer;
    }
}
</style>