var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "release-details-dialog" },
    [
      _c(
        "el-dialog",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: {
            title: "发放明细",
            visible: _vm.dialogVisible,
            width: "748px",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "content" }, [
            _c("div", { staticClass: "header" }, [
              _c("span", { staticClass: "item" }, [
                _vm._v("总金额"),
                _c("i", [
                  _vm._v(_vm._s(_vm.numberFormatter(_vm.aggregateAmount))),
                ]),
              ]),
              _c("span", { staticClass: "item" }, [
                _vm._v("已领取金额"),
                _c("i", [
                  _vm._v(_vm._s(_vm.numberFormatter(_vm.receivedAmount))),
                ]),
              ]),
              _c("span", { staticClass: "item" }, [
                _vm._v("发放个数"),
                _c("i", [_vm._v(_vm._s(_vm.sendCount))]),
              ]),
              _c("span", { staticClass: "item" }, [
                _vm._v("领取个数"),
                _c("i", [_vm._v(_vm._s(_vm.receivedCount))]),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "details-table" },
              [
                _c(
                  "el-table",
                  {
                    staticStyle: { width: "100%" },
                    attrs: { data: _vm.detailsData },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        prop: "receivedTime",
                        label: "领取时间",
                        formatter: _vm.dateFormatter,
                        width: "220",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "receiverName", label: "用户" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "div",
                                { staticClass: "user-info" },
                                [
                                  _c("avatar", {
                                    attrs: {
                                      userId: scope.row.receiverId,
                                      enableLink: false,
                                      src: scope.row.avatarUrl,
                                      size: "sm",
                                    },
                                  }),
                                  _c("span", { staticClass: "name" }, [
                                    _vm._v(_vm._s(scope.row.receiverName)),
                                  ]),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "amount", label: "金额", width: "200" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(
                                    _vm.numberFormatter(scope.row.amount)
                                  ) +
                                  "\n                        "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }