import { render, staticRenderFns } from "./offer-broadcast-set.vue?vue&type=template&id=c3d7938e&scoped=true&"
import script from "./offer-broadcast-set.vue?vue&type=script&lang=js&"
export * from "./offer-broadcast-set.vue?vue&type=script&lang=js&"
import style0 from "./offer-broadcast-set.vue?vue&type=style&index=0&id=c3d7938e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c3d7938e",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\Work\\CSDN\\Code\\baza-lbd-portal-frontend\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('c3d7938e')) {
      api.createRecord('c3d7938e', component.options)
    } else {
      api.reload('c3d7938e', component.options)
    }
    module.hot.accept("./offer-broadcast-set.vue?vue&type=template&id=c3d7938e&scoped=true&", function () {
      api.rerender('c3d7938e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/offer-broadcast/layout/offer-broadcast-set.vue"
export default component.exports