<template>
    <div class="offer-broadcast-set" v-loading="loading">
        <div class="set-item">
            <h5 class="set-title">Offer播报配置</h5>
            <el-form :model="offerBroadcastForm" :rules="rules" ref="offerBroadcastForm" label-width="100px" class="offer-broadcast-form">
                <el-form-item label="当前播报推送" prop="status">
                    <el-radio-group v-model="offerBroadcastForm.status" :disabled="!canEdit" @change="offerBroadcastStatusChange">
                        <el-radio-button :label="true">开启</el-radio-button>
                        <el-radio-button :label="false">关闭</el-radio-button>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="播报推送时机" prop="triggerType">
                    <el-radio-group v-model="offerBroadcastForm.triggerType" :disabled="!canEdit || !offerBroadcastForm.status" @change="triggerTypeChange">
                        <el-radio :label="0">点击Offer后立即推送播报</el-radio>
                        <div class="my-time-radio">
                            <el-radio :label="1">点击Offer后在工作日的</el-radio>
                            <!-- arrow-control -->
                            <!-- <el-time-picker
                                :disabled="!canEdit || !offerBroadcastForm.status || offerBroadcastForm.triggerType != 1"
                                v-model="offerBroadcastForm.triggerTime"
                                format="HH:mm"
                                value-format="HH:mm"
                                :picker-options="{
                                    selectableRange: '9:30:00 - 20:30:59'
                                }"
                                placeholder="选择时间">
                            </el-time-picker> -->

                            <el-time-select
                                :disabled="!canEdit || !offerBroadcastForm.status || offerBroadcastForm.triggerType != 1"
                                v-model="offerBroadcastForm.triggerTime"
                                :picker-options="{
                                    start: '09:30',
                                    step: '00:05',
                                    end: '20:30'
                                }"
                                placeholder="选择时间">
                                </el-time-select>
                        </div>
                    </el-radio-group>
                    <span>点推送播报</span>
                </el-form-item>
            </el-form>
        </div>
        <div class="set-item">
            <h5 class="set-title">福利红包配置</h5>
            <el-form :model="redPacketForm" :rules="rules" ref="redPacketForm" label-width="100px" class="offer-broadcast-form">
                <el-form-item label="红包推送" prop="redPacketStatus">
                    <el-radio-group v-model="redPacketForm.redPacketStatus" :disabled="!canEdit || !offerBroadcastForm.status" @change="redPacketStatusChange">
                        <el-radio-button :label="true">开启</el-radio-button>
                        <el-radio-button :label="false">关闭</el-radio-button>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="红包推送金额">
                    <div class="red-packet-money-box">
                        <div class="col">
                            <!-- <el-form-item class="my-defined-form-item" label="红包金额共计" prop="redPacketAmount">
                                <span>红包金额共计</span>
                                <el-input
                                    :disabled="!canEdit || !offerBroadcastForm.status || !redPacketForm.redPacketStatus"
                                    v-model="redPacketForm.redPacketAmount"
                                    oninput="value=value.replace(/[^0-9.]/g,'') "
                                    placeholder="输入金额">
                                    <template slot="append">元</template>
                                </el-input>
                            </el-form-item>
                            <el-form-item class="my-defined-form-item" label="分成"  prop="redPacketCount">
                                <span>分成</span>
                                <el-input
                                    :disabled="!canEdit || !offerBroadcastForm.status || !redPacketForm.redPacketStatus"
                                    v-model="redPacketForm.redPacketCount"
                                    oninput="value=value.replace(/[^0-9]/g,'')"
                                    maxlength="2"
                                    placeholder="输入数量">
                                    <template slot="append">个</template>
                                </el-input>
                            </el-form-item> -->
                            <el-form-item class="my-defined-form-item" prop="redPacketPushMoney">
                                <div class="my-defined-form-item">
                                    <span>红包金额共计</span>
                                    <el-input
                                        :disabled="!canEdit || !offerBroadcastForm.status || !redPacketForm.redPacketStatus"
                                        v-model="redPacketForm.redPacketAmount"
                                        oninput="value=value.replace(/[^0-9.]/g,'') "
                                        placeholder="输入金额">
                                        <template slot="append">元</template>
                                    </el-input>
                                </div>
                                <div class="my-defined-form-item">
                                    <span>分成</span>
                                    <el-input
                                        :disabled="!canEdit || !offerBroadcastForm.status || !redPacketForm.redPacketStatus"
                                        v-model="redPacketForm.redPacketCount"
                                        oninput="value=value.replace(/[^0-9]/g,'')"
                                        maxlength="2"
                                        placeholder="输入数量">
                                        <template slot="append">个</template>
                                    </el-input>
                                </div>
                            </el-form-item>
                        </div>
                        <div class="col">
                            <el-form-item class="my-defined-form-item" label="每个红包金额" prop="isEqualDivision">
                                <span>每个红包金额</span>
                                <el-radio-group v-model="redPacketForm.isEqualDivision" :disabled="!canEdit || !offerBroadcastForm.status || !redPacketForm.redPacketStatus">
                                    <el-radio :label="false">随机</el-radio>
                                    <el-radio :label="true">平均</el-radio>
                                </el-radio-group>
                                <span><i class="average-money">{{ averageAmount }}</i>元</span>
                            </el-form-item>
                        </div>
                    </div>
                </el-form-item>
                <el-form-item label="推送时间">
                    <div class="red-packet-money-box">
                        <div class="col">
                            <el-form-item class="my-defined-form-item" label="offe播报后的" prop="limitMinutes">
                                <span>Offer播报后的</span>
                                <el-input
                                    :disabled="!canEdit || !offerBroadcastForm.status || !redPacketForm.redPacketStatus"
                                    v-model="redPacketForm.limitMinutes"
                                    oninput="value=value.replace(/[^0-9]/g,'')"
                                    placeholder="请填写时间">
                                </el-input>
                                <span>分钟内随机发放</span>
                            </el-form-item>
                        </div>
                        <div class="col">
                            <p class="tip">
                                仅在工作日9:30~12:00、13:30~20:30计算时间，非工作时间不计算
                            </p>
                        </div>
                    </div>
                </el-form-item>
            </el-form>
        </div>
        <div class="option-btn">
            <template v-if="!canEdit">
                <el-button type="primary" @click="handleClick('edit')">编辑</el-button>
            </template>
            <template v-else>
                <el-button type="primary" @click="submit" :loading="saveLoading">保存</el-button>
                <el-button @click="handleClick('cancel')">取消</el-button>
            </template>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import broadcastService from '#/js/service/broadcastService.js';

export default {
    data() {
        var validateTriggerType = (rule, value, callback) => {
            // console.log(this.offerBroadcastForm.status);
            // console.log(value);
            if(this.offerBroadcastForm.status) { // offer播报开启
                if(value !== 0 && !value) {
                    callback(new Error(`请选择播报推送时机`));
                } else {
                    if(value == 1) {
                        if(!this.offerBroadcastForm.triggerTime) {
                            callback(new Error(`请选择播报时间`));
                        } else {
                            callback();
                        }
                    } else if(value == 0) {
                        callback();
                    } else {
                        callback();
                    }
                }
            } else { // offer播报关闭
                callback();
            }
            
        };
        var validateRedPacketPushMoney = (rule, value, callback) => {
            let amountReg = /(^[1-9](\d+)?(\.\d{1,2})?$)|(^\d\.\d{1,2}$)/;
            if(this.redPacketForm.redPacketAmount == '') {
                return callback(new Error(`请输入红包金额`));
            } if(Number(this.redPacketForm.redPacketAmount) < 1 || Number(this.redPacketForm.redPacketAmount) > 500) {
                return callback(new Error(`红包金额为1-500元`));
            } else if(!amountReg.test(this.redPacketForm.redPacketAmount)) {
                return callback(new Error(`请输入正确的金额并保留两位小数`));
            }
            
            if(this.redPacketForm.redPacketCount == '') {
                return callback(new Error(`请输入红包数量`));
            } else if(Number(this.redPacketForm.redPacketCount) <= 0 || Number(this.redPacketForm.redPacketCount) > 99) {
                return callback(new Error(`红包数量不少于1个,上限99个`));
            } 

            if(Number(this.redPacketForm.redPacketCount) > Number(this.redPacketForm.redPacketAmount)) {
                return callback(new Error(`红包总额不能小于分成个数`));
            }

            callback();
        };
        // var validateRedPacketAmount = (rule, value, callback) => {
        //     // console.log(value);
        //     let amountReg = /(^[1-9](\d+)?(\.\d{1,2})?$)|(^\d\.\d{1,2}$)/
        //     if(this.redPacketForm.redPacketStatus) {
        //         if(Number(value) < 1 || Number(value) > 500) {
        //             callback(new Error(`红包金额为1-500元`));
        //         } else if(!amountReg.test(value)) {
        //             callback(new Error(`请输入正确的金额并保留两位小数`));
        //         } else {
        //             callback();
        //         }
        //     } else {
        //         callback();
        //     }
        // };
        // var validateRedPacketCount = (rule, value, callback) => {
        //     // console.log(value);
        //     if(Number(value) <= 0 || Number(value) > 99) {
        //         callback(new Error(`红包数量不少于1个,上限99个`));
        //     } else {
        //         callback();
        //     }
        // };
        var validateLimitMinutes = (rule, value, callback) => {
            // console.log(value);
            if(Number(value) < 5 || Number(value) > 500) {
                callback(new Error(`时间在5分钟到500分钟之间`));
            } else {
                callback();
            }
        };

        return {
            loading: false,
            saveLoading: false,

            canEdit: false,

            id: null,
            offerBroadcastForm: {
                status: true,
                triggerType: '',
                triggerTime: '',
            },
            redPacketForm: {
                redPacketStatus: true,
                redPacketPushMoney: '',
                redPacketAmount: '',
                redPacketCount: '',
                isEqualDivision: '',
                limitMinutes: '',
            },
            rules: {
                status: [
                    { required: true, message: '请选择offer播报推送是否开启', trigger: 'change' },
                ],
                triggerType: [
                    { validator: validateTriggerType, trigger: ['change', 'blur'] }
                ],

                redPacketStatus: [
                    { required: true, message: '请选择红包推送是否开启', trigger: 'change' },
                ],
                redPacketPushMoney: [
                    { required: true, validator: validateRedPacketPushMoney, trigger: ['change', 'blur'] }
                ],
                // redPacketAmount: [
                //     { required: true, message: '请输入红包金额', trigger: ['change', 'blur'] },
                //     { validator: validateRedPacketAmount, trigger: ['change', 'blur'] }
                // ],
                // redPacketCount: [
                //     { required: true, message: '请输入红包数量', trigger: ['change', 'blur'] },
                //     { validator: validateRedPacketCount, trigger: ['change', 'blur'] }
                // ],
                isEqualDivision: [
                    { required: true, message: '请选择红包分配方式', trigger: 'change' },
                ],
                limitMinutes: [
                    { required: true, message: '请输入推送时间', trigger: ['change', 'blur'] },
                    { validator: validateLimitMinutes, trigger: ['change', 'blur'] }
                ],
            },
        };
    },

    mounted() {
        this.init();
    },

    watch: {

    },

    computed: {
        averageAmount() {
            let amount = this.redPacketForm.redPacketAmount;
            let count = this.redPacketForm.redPacketCount;
            let res = amount / count;
            // 例如：100/''，100/0的情况 值会无穷大(Infinity)
            // isfinite是判断数组的元素是否是有界的（即不是inf）
            if(!isFinite(res)) {
                res = 0;
            }
            // 四舍五入，保留两位小数
            return Math.round(res*100)/100 || 0;
        }
    },

    methods: {
        init() {
            this.loading = true;
            broadcastService
                .getOfferBroadcastSetting()
                .then(res => {
                    // console.log(res);
                    this.id = res.id;

                    this.offerBroadcastForm.status = res.status;
                    this.offerBroadcastForm.triggerType = res.triggerType;
                    // this.offerBroadcastForm.triggerTime = res.triggerTime;
                    this.offerBroadcastForm.triggerTime = res.triggerTime ? moment(res.triggerTime).format('HH:mm') : '';

                    this.redPacketForm.redPacketStatus = res.redPacketStatus;
                    this.redPacketForm.redPacketAmount = res.redPacketAmount || '';
                    this.redPacketForm.redPacketCount = res.redPacketCount || '';
                    this.redPacketForm.isEqualDivision = res.isEqualDivision;
                    this.redPacketForm.limitMinutes = res.limitMinutes || '';
                }).catch((err) => {
                    console.log(err);
                }).finally(() => {
                    this.loading = false;
                });
        },

        offerBroadcastStatusChange(status) {
            if(!status) {
                this.resetForm('offerBroadcastForm');
                this.resetForm('redPacketForm');

                this.redPacketForm.redPacketStatus = false;
            } 
        },
        redPacketStatusChange(status) {
            if(!status) {
                this.resetForm('redPacketForm');
            } 
        },
        triggerTypeChange(val) {
            if(val === 0) {
                this.offerBroadcastForm.triggerTime = '';
            }
        },

        // 底部操作按钮点击
        handleClick(type) {
            console.log(type);
            if(type == 'edit') {
                this.canEdit = true;
            } else { // 点击取消
                this.canEdit = false;

                this.init();
            }
        },

        // 重置
        resetForm(formName) {
            // 清空校验错误提示和重置表内内容为默认值
            // this.$refs[formName].resetFields();
            // 清空校验错误提示
            this.$refs[formName].clearValidate();
        },

        // 点击保存
        submit() {
            this.$refs['offerBroadcastForm'].validate((valid) => {
                if (valid) {
                    if(this.offerBroadcastForm.status && this.redPacketForm.redPacketStatus) { // offre播报开启并且红包推送开启
                        this.$refs['redPacketForm'].validate((valid) => {
                            if (valid) {
                                // console.log('校验红包推送配置之后保存-------');
                                this.loading = true;
                                this.saveLoading = true;
                                let params = Object.assign({}, this.offerBroadcastForm, this.redPacketForm);
                                params.id = this.id;
                                // 当offer播报有值时，并且值是'16:07'这种格式时 需要转下传值格式；否则就拿接口那边返回的值（2023-07-05 17:10）
                                if(params.triggerTime && params.triggerTime.length == 5) {
                                    params.triggerTime = `${moment(new Date()).format('YYYY-MM-DD')} ${params.triggerTime}`;
                                }
                                if(params.triggerTime == '') {
                                   params.triggerTime = `${moment(new Date()).format('YYYY-MM-DD')} 00:00`;
                                }
                                params.redPacketAmount = params.redPacketAmount || 0;
                                params.redPacketCount = params.redPacketCount || 0;
                                params.limitMinutes = params.limitMinutes || 0;
                                delete params.redPacketPushMoney;
                                // console.log(params);

                                broadcastService
                                    .updateOfferBroadcastSetting(params)
                                    .then(res => {
                                        // console.log(res);
                                        shortTips('保存成功');

                                        this.canEdit = false;
                                        this.init();
                                    }).catch((err) => {
                                        console.log(err);
                                    }).finally(() => {
                                        this.loading = false;
                                        this.saveLoading = false;
                                    });
                            } else {
                                console.log('福利红包配置：error submit!!');
                                return false;
                            }
                        });
                    } else { // offre播报开启，红包推送关闭；offre播报关闭，红包推送关闭
                        // console.log('不管红包推送配置保存-------');
                        this.loading = true;
                        this.saveLoading = true;
                        let params = Object.assign({}, this.offerBroadcastForm, this.redPacketForm);
                        params.id = this.id;
                        // 当offer播报有值时，并且值是'16:07'这种格式时 需要转下传值格式；否则就拿接口那边返回的值（2023-07-05 17:10）
                        if(params.triggerTime && params.triggerTime.length == 5) {
                            params.triggerTime = `${moment(new Date()).format('YYYY-MM-DD')} ${params.triggerTime}`;
                        }
                        if(params.triggerTime == '') {
                            params.triggerTime = `${moment(new Date()).format('YYYY-MM-DD')} 00:00`;
                        }
                        params.redPacketAmount = params.redPacketAmount || 0;
                        params.redPacketCount = params.redPacketCount || 0;
                        params.limitMinutes = params.limitMinutes || 0;
                        delete params.redPacketPushMoney;
                        // console.log(params);
                        broadcastService
                            .updateOfferBroadcastSetting(params)
                            .then(res => {
                                // console.log(res);
                                shortTips('保存成功');

                                this.canEdit = false;
                                this.init();
                            }).catch((err) => {
                                console.log(err);
                            }).finally(() => {
                                this.loading = false;
                                this.saveLoading = false;
                            });
                    }
                } else {
                    console.log('Offer播报配置：error submit!!');
                    return false;
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.offer-broadcast-set {
    padding: 30px;

    .set-item {
        .set-title {
            font-size: 20px;
            color: #333333;
            margin-bottom: 30px;
            &::before {
                content: "";
                display: inline-block;
                height: 20px;
                width: 4px;
                background-color: #38bc9d;
                margin-right: 12px;
                vertical-align: top;
            }
        }

        /deep/ .offer-broadcast-form.el-form {
            .el-form-item {
                margin-bottom: 38px;
                .el-form-item__label:before {
                    content: '*';
                    color: #F56C6C;
                    margin-right: 4px;
                }
                .el-form-item__label {
                    width: 130px !important;
                    font-size: 14px;
                    color: #999999;
                    text-align: left;
                }
                .el-form-item__content {
                    margin-left: 130px !important;
                }

                // 禁用状态下的样式
                .el-radio-button__orig-radio:disabled + .el-radio-button__inner {
                    color: #606266;
                }
                .el-radio-button__orig-radio:disabled:checked + .el-radio-button__inner {
                    background-color: #38bc9d;
                    color: #fff;
                } 
                .el-radio__input.is-disabled + span.el-radio__label {
                    color: #666;
                }

                .el-input.is-disabled .el-input__inner {
                    // background-color: #fff;
                    background-color: #F5F5F5;
                    color: #333333;
                }

                .my-time-radio {
                    display: inline-block;
                    .el-radio {
                        margin-right: 0;
                    }
                    .el-date-editor {
                        margin: 0 10px;
                        width: 180px;
                        &.el-input .el-input__inner:focus {
                            border: 1px solid #ddd;
                        }
                    }
                }

                .red-packet-money-box {
                    .col {
                        &:first-child {
                            margin-bottom: 30px;
                        }
                        .my-defined-form-item {
                            display: inline-block;
                            margin: 0 30px 0 0;

                            .el-form-item__label {
                                width: 0 !important;
                                display: none;
                            }
                            .el-form-item__content {
                                margin-left: 0 !important;
                            }

                            .el-input {
                                width: 180px;
                                border: none;
                                margin: 0 10px;
                                border: 1px solid #CCCCCC;
                                border-radius: 4px;
                                .el-input__inner {
                                    border: none;
                                }
                                .el-input-group__append {
                                    background: inherit;
                                    // background-color: #fff;
                                    padding-left: 10px;
                                    border: none;
                                }

                                &.is-disabled {
                                    .el-input-group__append {
                                        background-color: #F5F5F5;
                                        color: #606266;
                                    }
                                }
                            }
                        }

                        .el-radio-group {
                            margin: 0 10px;
                        }

                        i.average-money {
                            color: #FC7859;
                        }

                        .tip {
                            color: #E24E3A;
                        }
                    }
                }
            }
        }
    }

    .option-btn {
        padding-left: 130px;
        .el-button {
            border-radius: 4px;
        }
    }
}
</style>