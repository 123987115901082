<template>
    <div class="offer-broadcast">
        <div class="offer-broadcast-wrap">
            <div class="top-tab">
                <el-tabs v-model="activeTabName" @tab-click="handleClick">
                    <el-tab-pane label="Offer播报配置" name="offerBroadcastSet"></el-tab-pane>
                    <el-tab-pane label="数据统计" name="dataStatistics"></el-tab-pane>
                </el-tabs>
            </div>
            <div class="content-wrap">
                <!-- Offer播报配置 -->
                <template v-if="activeTabName == 'offerBroadcastSet'">
                    <offer-broadcast-set></offer-broadcast-set>
                </template>

                <!-- 数据统计 -->
                <template v-if="activeTabName == 'dataStatistics'">
                    <data-statistics-table></data-statistics-table>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import offerBroadcastSet from './layout/offer-broadcast-set.vue';
import dataStatisticsTable from './layout/data-statistics-table.vue';

export default {
    components: {
        offerBroadcastSet,
        dataStatisticsTable,
    },
    data() {
        return {
            activeTabName: 'offerBroadcastSet',
        };
    },

    mounted() {
        
    },

    methods: {
        handleClick(tab, event) {
            // console.log(tab, event);
            this.activeTabName = tab.name;
        },
    },
};
</script>

<style lang="scss" scope>
.offer-broadcast{
    height: 100%;
    overflow-y: auto;
    padding: 20px;

    .offer-broadcast-wrap {
        background-color: #ffffff;
        border-radius: 8px;

        .top-tab {
            height: 60px;
            .el-tabs__nav-scroll {
                // padding: 0 40px;
            }
            .el-tabs__nav-wrap::after {
                height: 1px;
                background-color: #EEEEEE;
            }
            .el-tabs__active-bar {
                height: 4px;
            }
            .el-tabs__item {
                height: 60px;
                line-height: 60px;
                padding: 0 40px;
                color: #999999;
                &.is-active {
                    color: #38BC9D;
                }
            }
        }

        .content-wrap {
            min-height: calc(100vh - 144px);
        }
    }
}
</style>