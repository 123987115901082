var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "offer-broadcast" }, [
    _c("div", { staticClass: "offer-broadcast-wrap" }, [
      _c(
        "div",
        { staticClass: "top-tab" },
        [
          _c(
            "el-tabs",
            {
              on: { "tab-click": _vm.handleClick },
              model: {
                value: _vm.activeTabName,
                callback: function ($$v) {
                  _vm.activeTabName = $$v
                },
                expression: "activeTabName",
              },
            },
            [
              _c("el-tab-pane", {
                attrs: { label: "Offer播报配置", name: "offerBroadcastSet" },
              }),
              _c("el-tab-pane", {
                attrs: { label: "数据统计", name: "dataStatistics" },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "content-wrap" },
        [
          _vm.activeTabName == "offerBroadcastSet"
            ? [_c("offer-broadcast-set")]
            : _vm._e(),
          _vm.activeTabName == "dataStatistics"
            ? [_c("data-statistics-table")]
            : _vm._e(),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }