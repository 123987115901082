var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "offer-broadcast-statistics-table" },
    [
      _c(
        "div",
        { staticClass: "header-option-btn" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: { click: _vm.goToWechatPlatform },
            },
            [_vm._v("微信商户平台")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.exportLoading },
              on: { click: _vm.handleExport },
            },
            [_vm._v("导出明细")]
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticStyle: { width: "100%" },
          attrs: { data: _vm.tableData, border: "", "tooltip-effect": "light" },
        },
        [
          _c("el-table-column", {
            attrs: {
              prop: "offerTime",
              align: "left",
              width: "180",
              formatter: _vm.dateFormatter,
              "show-overflow-tooltip": true,
              label: "Offer时间",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "sendingTime",
              align: "left",
              width: "180",
              formatter: _vm.dateFormatter,
              "show-overflow-tooltip": true,
              label: "播报推送时间",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "customerName",
              align: "left",
              width: "200",
              "show-overflow-tooltip": true,
              label: "客户",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "recommendName",
              align: "left",
              width: "200",
              "show-overflow-tooltip": true,
              label: "推荐人",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "jobName",
              align: "left",
              width: "200",
              "show-overflow-tooltip": true,
              label: "推荐职位",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "aggregateAmount",
              align: "left",
              width: "160",
              "show-overflow-tooltip": true,
              label: "红包金额",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.numberFormatter(scope.row.aggregateAmount)) +
                        "\n            "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "receivedAmount",
              align: "left",
              width: "160",
              "show-overflow-tooltip": true,
              label: "已领取金额",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.numberFormatter(scope.row.receivedAmount)) +
                        "\n            "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "操作",
              width: "140",
              align: "center",
              resizable: false,
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "span",
                      {
                        staticClass: "details",
                        on: {
                          click: function ($event) {
                            return _vm.viewDetail(scope.row)
                          },
                        },
                      },
                      [_vm._v("明细")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "work-table-footer" },
        [
          _c(
            "el-pagination",
            {
              staticClass: "el-pagination-workTable",
              attrs: {
                layout: "total, sizes, prev, pager, next, slot",
                "current-page": _vm.page.current,
                "page-sizes": [15, 20, 50],
                "page-size": _vm.page.size,
                total: _vm.page.total,
              },
              on: {
                "size-change": _vm.handleSizeChange,
                "current-change": _vm.handleCurrentChange,
              },
            },
            [
              _c("span", { staticClass: "pagination-text" }, [
                _c(
                  "span",
                  [
                    _vm._v("前往"),
                    _c("el-input", {
                      nativeOn: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.handlePagerJump.apply(null, arguments)
                        },
                      },
                      model: {
                        value: _vm.pagerJump,
                        callback: function ($$v) {
                          _vm.pagerJump = $$v
                        },
                        expression: "pagerJump",
                      },
                    }),
                    _vm._v("页"),
                  ],
                  1
                ),
                _c("span", { on: { click: _vm.handlePagerJump } }, [
                  _vm._v("跳转"),
                ]),
              ]),
            ]
          ),
        ],
        1
      ),
      _c("release-details-dialog", { ref: "releaseDetailsDialog" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }