var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "offer-broadcast-set",
    },
    [
      _c(
        "div",
        { staticClass: "set-item" },
        [
          _c("h5", { staticClass: "set-title" }, [_vm._v("Offer播报配置")]),
          _c(
            "el-form",
            {
              ref: "offerBroadcastForm",
              staticClass: "offer-broadcast-form",
              attrs: {
                model: _vm.offerBroadcastForm,
                rules: _vm.rules,
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "当前播报推送", prop: "status" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { disabled: !_vm.canEdit },
                      on: { change: _vm.offerBroadcastStatusChange },
                      model: {
                        value: _vm.offerBroadcastForm.status,
                        callback: function ($$v) {
                          _vm.$set(_vm.offerBroadcastForm, "status", $$v)
                        },
                        expression: "offerBroadcastForm.status",
                      },
                    },
                    [
                      _c("el-radio-button", { attrs: { label: true } }, [
                        _vm._v("开启"),
                      ]),
                      _c("el-radio-button", { attrs: { label: false } }, [
                        _vm._v("关闭"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "播报推送时机", prop: "triggerType" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: {
                        disabled:
                          !_vm.canEdit || !_vm.offerBroadcastForm.status,
                      },
                      on: { change: _vm.triggerTypeChange },
                      model: {
                        value: _vm.offerBroadcastForm.triggerType,
                        callback: function ($$v) {
                          _vm.$set(_vm.offerBroadcastForm, "triggerType", $$v)
                        },
                        expression: "offerBroadcastForm.triggerType",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 0 } }, [
                        _vm._v("点击Offer后立即推送播报"),
                      ]),
                      _c(
                        "div",
                        { staticClass: "my-time-radio" },
                        [
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("点击Offer后在工作日的"),
                          ]),
                          _c("el-time-select", {
                            attrs: {
                              disabled:
                                !_vm.canEdit ||
                                !_vm.offerBroadcastForm.status ||
                                _vm.offerBroadcastForm.triggerType != 1,
                              "picker-options": {
                                start: "09:30",
                                step: "00:05",
                                end: "20:30",
                              },
                              placeholder: "选择时间",
                            },
                            model: {
                              value: _vm.offerBroadcastForm.triggerTime,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.offerBroadcastForm,
                                  "triggerTime",
                                  $$v
                                )
                              },
                              expression: "offerBroadcastForm.triggerTime",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("span", [_vm._v("点推送播报")]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "set-item" },
        [
          _c("h5", { staticClass: "set-title" }, [_vm._v("福利红包配置")]),
          _c(
            "el-form",
            {
              ref: "redPacketForm",
              staticClass: "offer-broadcast-form",
              attrs: {
                model: _vm.redPacketForm,
                rules: _vm.rules,
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "红包推送", prop: "redPacketStatus" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: {
                        disabled:
                          !_vm.canEdit || !_vm.offerBroadcastForm.status,
                      },
                      on: { change: _vm.redPacketStatusChange },
                      model: {
                        value: _vm.redPacketForm.redPacketStatus,
                        callback: function ($$v) {
                          _vm.$set(_vm.redPacketForm, "redPacketStatus", $$v)
                        },
                        expression: "redPacketForm.redPacketStatus",
                      },
                    },
                    [
                      _c("el-radio-button", { attrs: { label: true } }, [
                        _vm._v("开启"),
                      ]),
                      _c("el-radio-button", { attrs: { label: false } }, [
                        _vm._v("关闭"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("el-form-item", { attrs: { label: "红包推送金额" } }, [
                _c("div", { staticClass: "red-packet-money-box" }, [
                  _c(
                    "div",
                    { staticClass: "col" },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "my-defined-form-item",
                          attrs: { prop: "redPacketPushMoney" },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "my-defined-form-item" },
                            [
                              _c("span", [_vm._v("红包金额共计")]),
                              _c(
                                "el-input",
                                {
                                  attrs: {
                                    disabled:
                                      !_vm.canEdit ||
                                      !_vm.offerBroadcastForm.status ||
                                      !_vm.redPacketForm.redPacketStatus,
                                    oninput:
                                      "value=value.replace(/[^0-9.]/g,'') ",
                                    placeholder: "输入金额",
                                  },
                                  model: {
                                    value: _vm.redPacketForm.redPacketAmount,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.redPacketForm,
                                        "redPacketAmount",
                                        $$v
                                      )
                                    },
                                    expression: "redPacketForm.redPacketAmount",
                                  },
                                },
                                [
                                  _c("template", { slot: "append" }, [
                                    _vm._v("元"),
                                  ]),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "my-defined-form-item" },
                            [
                              _c("span", [_vm._v("分成")]),
                              _c(
                                "el-input",
                                {
                                  attrs: {
                                    disabled:
                                      !_vm.canEdit ||
                                      !_vm.offerBroadcastForm.status ||
                                      !_vm.redPacketForm.redPacketStatus,
                                    oninput:
                                      "value=value.replace(/[^0-9]/g,'')",
                                    maxlength: "2",
                                    placeholder: "输入数量",
                                  },
                                  model: {
                                    value: _vm.redPacketForm.redPacketCount,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.redPacketForm,
                                        "redPacketCount",
                                        $$v
                                      )
                                    },
                                    expression: "redPacketForm.redPacketCount",
                                  },
                                },
                                [
                                  _c("template", { slot: "append" }, [
                                    _vm._v("个"),
                                  ]),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col" },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "my-defined-form-item",
                          attrs: {
                            label: "每个红包金额",
                            prop: "isEqualDivision",
                          },
                        },
                        [
                          _c("span", [_vm._v("每个红包金额")]),
                          _c(
                            "el-radio-group",
                            {
                              attrs: {
                                disabled:
                                  !_vm.canEdit ||
                                  !_vm.offerBroadcastForm.status ||
                                  !_vm.redPacketForm.redPacketStatus,
                              },
                              model: {
                                value: _vm.redPacketForm.isEqualDivision,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.redPacketForm,
                                    "isEqualDivision",
                                    $$v
                                  )
                                },
                                expression: "redPacketForm.isEqualDivision",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: false } }, [
                                _vm._v("随机"),
                              ]),
                              _c("el-radio", { attrs: { label: true } }, [
                                _vm._v("平均"),
                              ]),
                            ],
                            1
                          ),
                          _c("span", [
                            _c("i", { staticClass: "average-money" }, [
                              _vm._v(_vm._s(_vm.averageAmount)),
                            ]),
                            _vm._v("元"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
              _c("el-form-item", { attrs: { label: "推送时间" } }, [
                _c("div", { staticClass: "red-packet-money-box" }, [
                  _c(
                    "div",
                    { staticClass: "col" },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "my-defined-form-item",
                          attrs: {
                            label: "offe播报后的",
                            prop: "limitMinutes",
                          },
                        },
                        [
                          _c("span", [_vm._v("Offer播报后的")]),
                          _c("el-input", {
                            attrs: {
                              disabled:
                                !_vm.canEdit ||
                                !_vm.offerBroadcastForm.status ||
                                !_vm.redPacketForm.redPacketStatus,
                              oninput: "value=value.replace(/[^0-9]/g,'')",
                              placeholder: "请填写时间",
                            },
                            model: {
                              value: _vm.redPacketForm.limitMinutes,
                              callback: function ($$v) {
                                _vm.$set(_vm.redPacketForm, "limitMinutes", $$v)
                              },
                              expression: "redPacketForm.limitMinutes",
                            },
                          }),
                          _c("span", [_vm._v("分钟内随机发放")]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "col" }, [
                    _c("p", { staticClass: "tip" }, [
                      _vm._v(
                        "\n                            仅在工作日9:30~12:00、13:30~20:30计算时间，非工作时间不计算\n                        "
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "option-btn" },
        [
          !_vm.canEdit
            ? [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.handleClick("edit")
                      },
                    },
                  },
                  [_vm._v("编辑")]
                ),
              ]
            : [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary", loading: _vm.saveLoading },
                    on: { click: _vm.submit },
                  },
                  [_vm._v("保存")]
                ),
                _c(
                  "el-button",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.handleClick("cancel")
                      },
                    },
                  },
                  [_vm._v("取消")]
                ),
              ],
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }